import readXlsxFile, { readSheetNames } from 'read-excel-file';
import IndustryRow from '../../shared/models/shared/parseExcelFile/vesselGroupingIndustry.model';
import OrganizationRow from '../../shared/models/shared/parseExcelFile/vesselGroupingOrganization.model';
import { VesselGroupsPerVessel, VesselGroup, VesselGroupingParseResult } from '../../shared/models/shared/parseExcelFile/vesselGrouping.model';
import i18n from "i18next";
import { VesselGroupingService } from '../api/vessel/vesselGroupingService';
import { ClientUtility } from '../../utils/data/client.utility';
import { CustomerUtility } from '../../utils/data/customer.utility';
import { VesselGrouping } from '../../shared/models/vessel/vesselGrouping.model';
import { VesselProcessingStatus } from '../../shared/models/vessel/vesselProcessingStatus.enum';
import { EnumUtility } from "../../utils/enum/enum.utility";
import { ResponseMessage } from "../../shared/models/shared/error/errorResponse.model";

const IndustrySheetName = 'Industry';
const OrganizationSheetName = 'Organization';


export const parseVesselGroupingFile = async (file: File): Promise<VesselGroupingParseResult> => {
  
  const selectedClientId: string = ClientUtility.GetSelectedClientId() ?? "";
  const selectedCustomerId: string = CustomerUtility.GetSelectedCustomerId() ?? "";

  const vesselGroupingService = new VesselGroupingService();

  let result: VesselGroupingParseResult = {
    VesselGrouping: [] as VesselGroupsPerVessel[],
    Errors: []
  };
  
  try {
    let industryData : IndustryRow[] = [];
    let organizationData : OrganizationRow[] = [];

    if (file && !(file.name.toLowerCase().endsWith(".xlsx") || file.name.toLowerCase().endsWith(".xlsm"))) {
      result.Errors.push(i18n.t("service.parseexcelfile.please_upload_a_file_with_xlsx_or_xlsm_extension"));
      return result;
    }

    const sheets = await readSheetNames(file);

    let industrySheetExists = true;
    if (!sheets.includes(IndustrySheetName)) {
      result.Errors.push(i18n.t("service.parseexcelfile.excel_file_does_not_contain_sheet", { sheet: IndustrySheetName }));
      industrySheetExists = false;
    }

    let organizationSheetExists = true;
    if (!sheets.includes(OrganizationSheetName)) {
      result.Errors.push(i18n.t("service.parseexcelfile.excel_file_does_not_contain_sheet", { sheet: OrganizationSheetName }));
      organizationSheetExists = false;
    }

    if (industrySheetExists) {
      const industryRows = await readXlsxFile(file, { sheet: IndustrySheetName });
      const industryHeaders = industryRows[0].map((cell: any) => String(cell));
      if (!validateIndustryColumns(industryHeaders)) {
        result.Errors.push(i18n.t("service.parseexcelfile.sheet_is_missing_required_columns", { sheet: IndustrySheetName}));
      } else {
        industryData = parseIndustryTab(industryRows);
      }
    }

    if (organizationSheetExists) {
      const organizationRows = await readXlsxFile(file, {sheet: OrganizationSheetName});
      const organizationHeaders = organizationRows[0].map((cell: any) => String(cell));
      if (!validateOrganizationColumns(organizationHeaders)) {
        result.Errors.push(i18n.t("service.parseexcelfile.sheet_is_missing_required_columns", {sheet: OrganizationSheetName}));
      } else {
        organizationData = parseOrganizationTab(organizationRows);
      }
    }

    if (result.Errors.length === 0) {

      let vesselGroupingItems = await vesselGroupingService.getGroupingItemsAsync(selectedCustomerId, selectedClientId);

      industryData.forEach((industryRow) => {
        createVesselGroupsFromIndustryRow(industryRow).forEach((vesselGroup) => {
          addVesselGroup(industryRow.Imo.toString(), vesselGroup, result.VesselGrouping, vesselGroupingItems);
        });
      });
    
      organizationData.forEach((organizationRow) => {
        createVesselGroupsFromOrganizationRow(organizationRow).forEach((vesselGroup) => {
          addVesselGroup(organizationRow.IMO.toString(), vesselGroup, result.VesselGrouping, vesselGroupingItems);
        });
      });
    }

  } catch (error: any) {
    const errorResponse: ResponseMessage | null = error?.response?.data;
    if (errorResponse?.errors) {
        result.Errors.push(...errorResponse?.errors?.map(error => error?.message));
    } else {
        result.Errors.push(error?.message?.toString());
    }
  }

  return result;
};

const validateIndustryColumns = (headers: string[]): boolean => {
  const requiredIndustryColumns = [
    'IMO',
    'By Ship Type',
    'By Size',
    'By Cargo Carried',
    'By Class',
    'By Operation'
  ];

  const hasAllRequiredColumns = requiredIndustryColumns.every(column => headers.includes(column));
  const hasExactlyTheSameColumns = requiredIndustryColumns.length === headers.length;

  return hasAllRequiredColumns && hasExactlyTheSameColumns;
};

const validateOrganizationColumns = (headers: string[]): boolean => {
  const requiredOrganizationColumns = [
    'IMO',
    'Sister Class'
  ];

  return requiredOrganizationColumns.every(column => headers.includes(column));
};

const parseIndustryTab = (rows: any[]): IndustryRow[] => {
  const headers = rows[0];
  const data = rows.slice(1);
  const industryData: IndustryRow[] = [];

  data.forEach((row) => {
    const industryRow: IndustryRow = {
      Imo: row[headers.indexOf('IMO')] || '',
      ByShipType: row[headers.indexOf('By Ship Type')] || '',
      BySize: row[headers.indexOf('By Size')] || '',
      ByCargoCarried: row[headers.indexOf('By Cargo Carried')] || '',
      ByClass: row[headers.indexOf('By Class')] || '',
      ByOperation: row[headers.indexOf('By Operation')] || '',
    };
    if (industryRow.Imo) {
      industryData.push(industryRow);
    }
  });

  return industryData;
};

const parseOrganizationTab = (rows: any[]): OrganizationRow[] => {

  const headers = rows[0].map((header: string) => header.replace(/\s+/g, ''));
  const data = rows.slice(1);
  const organizationData: OrganizationRow[] = [];

  data.forEach((row) => {
    const organizationRow: OrganizationRow = {};
    headers.forEach((header: string, index: number) => {
      organizationRow[header] = row[index] || '';
    });
    if (organizationRow.IMO) {
      organizationData.push(organizationRow);
    }
  });

  return organizationData;
};

const createVesselGroup = (id: string, groupingOrder: number, groupingType: string, groupingDescription: string, groupingCategoryName: string, groupingName: string): VesselGroup => {
  return {
    Id: id,
    GroupingOrder: groupingOrder,
    GroupingType: groupingType,
    GroupingDescription: groupingDescription,
    GroupingCategoryName: groupingCategoryName,
    GroupingName: groupingName,
  };
};

const createVesselGroupsFromIndustryRow = (industryRow: IndustryRow): VesselGroup[] => {

  const groupingDescriptionPrefix = 'Vessel type - ';
  const vesselGroups: VesselGroup[] = [];

  const groupingFields = Object.keys(industryRow).filter(key => key !== 'Imo').map(key => {
    const category = formatCategory(key);
    return { field: key as keyof IndustryRow, category: category };
  });

  groupingFields.forEach((groupingField, index) => {
    const fieldValue = industryRow[groupingField.field];
    if (fieldValue) {
      vesselGroups.push(createVesselGroup("", index + 1, IndustrySheetName, groupingDescriptionPrefix + groupingField.category, groupingField.category, fieldValue));
    }
  });

  return vesselGroups;
};

const createVesselGroupsFromOrganizationRow = (organizationRow: OrganizationRow): VesselGroup[] => {

  const groupingDescriptionPrefix = 'By Design - ';
  const vesselGroups: VesselGroup[] = [];

  const groupingFields = Object.keys(organizationRow).filter(key => key !== 'IMO').map(key => {
    const category = formatCategory(key);
    return { field: key as keyof OrganizationRow, category: category };
  });

  // Ensure "Sister Class" is the first element, bacause of grouping index
  const sisterClassFieldIndex = groupingFields.findIndex(field => field.field === 'SisterClass');
  if (sisterClassFieldIndex > -1) {
    const [sisterClassField] = groupingFields.splice(sisterClassFieldIndex, 1);
    groupingFields.unshift(sisterClassField);
  }

  groupingFields.forEach((groupingField, index) => {
    const fieldValue = organizationRow[groupingField.field];
    if (fieldValue) {
      vesselGroups.push(createVesselGroup("", index + 1, OrganizationSheetName, groupingDescriptionPrefix + groupingField.category, groupingField.category, fieldValue));
    }
  });

  return vesselGroups;
};

const addVesselGroup = (vesselImo: string, vesselGroup: VesselGroup, vesselGroupsPerVessel: VesselGroupsPerVessel[], vesselGroupingItems : VesselGrouping[]) => {

  let status = "";
  let error = "";

  //console.log("addVesselGroup - vesselGroupingItems");
  //console.log(vesselGroupingItems);
  if (vesselGroupingItems.length > 0) {
    const vesselGroupingItem = vesselGroupingItems.find(
      (vesselGroupingItem) =>
        vesselGroupingItem.name === vesselGroup.GroupingName &&
        vesselGroupingItem.category === vesselGroup.GroupingCategoryName &&
        vesselGroupingItem.type === vesselGroup.GroupingType
    );
    if (vesselGroupingItem) {
      vesselGroup.Id = vesselGroupingItem.id;
      status = EnumUtility.GetEnumMemberName(VesselProcessingStatus, VesselProcessingStatus.VesselGroupsValidated) ?? "";
    }
    else {
      status = EnumUtility.GetEnumMemberName(VesselProcessingStatus, VesselProcessingStatus.FailedGroupValidation) ?? "";
      error = `Group with name ${vesselGroup.GroupingName} doesn't exist`;
    }
  }

  const existingVesselGroupsPerVessel = vesselGroupsPerVessel.find((vesselGroupPerVessel) => vesselGroupPerVessel.VesselImo === vesselImo);

  if (existingVesselGroupsPerVessel) {
    existingVesselGroupsPerVessel.Groups.push(vesselGroup);

    if (existingVesselGroupsPerVessel.Status === "" ||
      (existingVesselGroupsPerVessel.Status !== "" && status === (EnumUtility.GetEnumMemberName(VesselProcessingStatus, VesselProcessingStatus.FailedGroupValidation) ?? ""))
    ) {
      existingVesselGroupsPerVessel.Status = status;
    }

    if (error !== "") {
      existingVesselGroupsPerVessel.Errors.push(error);
    }
  } else {
    vesselGroupsPerVessel.push({
      VesselImo: vesselImo.toString(),
      Groups: [vesselGroup],
      Status: status,
      Errors: error !== "" ? [error] : []
    });
  }
};

const formatCategory = (category: string): string => {
  return category.replace(/([A-Z])/g, ' $1').trim();
}