import { createContext, useContext, useEffect, useState, ReactNode, useMemo, useRef } from "react";
import { ISelectedCustomer } from "./selectedCustomer.context.model";
import { useSelectedClientApp } from "../clientApplication/clientApplicationSelection.context";
import { CustomerService } from "../../../service/api/customer/customerService";
import { useGlobalProgressBarService } from "../globalProgressBar/globalProgressBar.Context";
import { storageConst } from "../../../constants/global.constants";
import { ICustomer } from "../../models/customer/customer.model";
import { SortingOption } from "../../../utils/filters/FilterSettings";
import { useSignalRService } from "../signalR/signalR.Context";
import { SignalRTargets } from "../../models/shared/signalR/enum/signalRTargets";
import { SignalRActionTypes } from "../../models/shared/signalR/enum/signalRActionTypes";

interface CustomerContextType {
  customers: ICustomer[];
  selectedCustomer: ISelectedCustomer | null;
  selectCustomer: (customer: ISelectedCustomer) => void;
}

export const CustomerContext = createContext<CustomerContextType | undefined>(undefined);

export const useSelectedCustomer = () => {
  const context = useContext(CustomerContext);
  if (context === undefined) {
    throw new Error("useSelectedCustomer must be used within a CustomerProvider");
  }
  return context;
};

export const CustomerProvider = ({ children }: { children: ReactNode }) => {
  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<ISelectedCustomer | null>(null);
  const { selectedClientApp } = useSelectedClientApp();
  const { addProgressItem, removeProgressItem } = useGlobalProgressBarService();
  const customerService = useMemo(() => new CustomerService(), []);
  const [sortState] = useState<SortingOption>(SortingOption.ASC);
  const { signalRData } = useSignalRService();

  const fetchCustomersRef = useRef<() => Promise<void>>();
  const clientIdRef = useRef<string | undefined>();
  const selectCustomerRef = useRef<(customer: ISelectedCustomer | null) => Promise<void>>();

  const sortCustomers = (customers: ICustomer[], sortState: SortingOption) => {
    return [...customers].sort((a, b) =>
      sortState === SortingOption.ASC ?
        a.name.localeCompare(b.name) :
        b.name.localeCompare(a.name));
  };

  selectCustomerRef.current = async (customer: ISelectedCustomer | null) => {

    if (!customer) {
      setSelectedCustomer(null);
      return;
    }

    try {
      addProgressItem();

      // Fetch the latest customer data from the API
      const clientId = selectedClientApp?.clientApp.id;      
      if (clientId) {

        let updatedSelectedCustomer: ISelectedCustomer = customer;

        if (customer && customer.id) {

          const updatedCustomer = await customerService.getCustomerByClientIdAsync(customer.id, clientId);

          updatedSelectedCustomer = {
            id: updatedCustomer.ccTenantId,
            name: updatedCustomer.tenantName,
            client_tenant_id: updatedCustomer.clientTenantId,
            partners: updatedCustomer.partners,
            vessels: updatedCustomer.vessels,
          };
        }

        setSelectedCustomer(updatedSelectedCustomer);

        localStorage.setItem(storageConst.SELECTED_CUSTOMER, JSON.stringify({ id: customer.id }));
        sessionStorage.setItem(storageConst.SELECTED_CUSTOMER, JSON.stringify({ id: customer.id }));
      }
    } catch (error) {
      console.error("Error fetching updated customer data:", error);
      setSelectedCustomer(null);
    } finally {
      removeProgressItem();
    }
  };

  fetchCustomersRef.current = async () => {
    try {
      addProgressItem();
      const clientId = selectedClientApp?.clientApp.id;
      if (clientId) {
        const response = await customerService.getCustomersByClientAsync(clientId);
        setCustomers(response);

        // Determine selected customer
        const lsCustomer = localStorage.getItem(storageConst.SELECTED_CUSTOMER);
        const savedCustomerId = lsCustomer ? JSON.parse(lsCustomer).id : "";
        const savedCustomer = response.find((c) => c.id === savedCustomerId);
        if (savedCustomer) {
          selectCustomerRef.current?.(savedCustomer);
        } else if (response.length > 0) {
          const sortedCustomers = sortCustomers(response, sortState);
          selectCustomerRef.current?.(sortedCustomers[0]);
        } else {
          selectCustomerRef.current?.(null);
        }
      } else {
        setCustomers([]);
        selectCustomerRef.current?.(null);
      }
    } catch (error) {
      console.error("Error fetching customers:", error);
      setCustomers([]);
      selectCustomerRef.current?.(null);
    } finally {
      removeProgressItem();
    }
  };

  useEffect(() => {
    clientIdRef.current = selectedClientApp?.clientApp.id;

    if (selectedClientApp?.clientApp.id) {
      fetchCustomersRef.current?.();
    } else {
      setCustomers([]);
      selectCustomerRef.current?.(null);
    }
  }, [selectedClientApp]);

  // Handle real-time updates from SignalR 
  useEffect(() => {
    if (signalRData?.Target === SignalRTargets.TenantUpdate) {
      const customer: ICustomer | null = signalRData?.Data.customer ?? null;
      const clientId = clientIdRef.current;
      if (signalRData?.Data.clientId === clientId && customer != null) {
        if (signalRData?.Data.action === SignalRActionTypes.AddCustomer) {
          setCustomers((prevCustomers) => [...prevCustomers, customer]);
        } else if (signalRData?.Data.action === SignalRActionTypes.UpdateCustomer) {
          setCustomers((prevCustomers) => prevCustomers.map((c) => (c.id === customer.id ? customer : c)));
        } else if (signalRData?.Data.action === SignalRActionTypes.DeleteCustomer) {
          setCustomers((prevCustomers) => prevCustomers.filter((c) => c.id !== customer.id));
        }
      }
    }
  }, [signalRData]);

  return (
    <CustomerContext.Provider value={{ customers, selectedCustomer, selectCustomer: selectCustomerRef.current! }}>
      {children}
    </CustomerContext.Provider>
  );
};