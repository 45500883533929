import React, { useMemo, useState } from "react";
import { Box, List, ListItem } from "@mui/material";
import { SearchCustomers } from "./searchCustomer.component";
import { useSelectedCustomer } from "../../../context/customer/customerSelection.Context";
import { SortingOption } from "../../../../utils/filters/FilterSettings";
import { theme } from "../../../../styles/theme";
import { AddCustomerButton } from "../add/addCustomerButton.component";

export const ListCustomerView = () => {
  const { customers, selectedCustomer, selectCustomer } = useSelectedCustomer();
  const [searchQuery, setSearchQuery] = useState("");
  const [sortState, setSortState] = useState(SortingOption.ASC);

  const handleSearch = (query: string) => setSearchQuery(query);

  const handleSort = () =>
    setSortState((prev) => (prev === SortingOption.ASC ? SortingOption.DESC : SortingOption.ASC));

  const filteredCustomers = useMemo(() => {
    const filtered = customers.filter((customer) =>
      customer.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    return filtered.sort((a, b) =>
      sortState === SortingOption.ASC
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name)
    );
  }, [customers, searchQuery, sortState]);  

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          width: "100%",
          marginBottom: "2px",
        }}
      >
        <AddCustomerButton />
      </Box>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          backgroundColor: theme.background.component,
          marginTop: "2px",
        }}
      >
        <SearchCustomers searchQuery={searchQuery} onSearch={handleSearch} onSortOrder={handleSort} />
      </Box>

      <Box sx={{ overflowY: "auto", flexGrow: 1 }}>
        <List sx={{ width: "100%" }}>
          {filteredCustomers.map((customer) => (
            <ListItem
              key={customer.id}
              onClick={() => selectCustomer(customer)}
              sx={{
                cursor: "pointer",
                backgroundColor: selectedCustomer?.id === customer.id ? theme.palette.primary.dark : "",
              }}
              data-id = "CustomerListItem"
            >
              {customer.name}
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};