import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import MuiXLicense from "./shared/components/mui/MuiXLicense";
import { msalInstance, initializeMsalInstance } from "./auth";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./styles/theme";
import { RouterProvider } from "react-router-dom";
import { router } from "./router/router";
import { MsalProvider } from "@azure/msal-react";
import "./localization/i18n";
import { CustomerProvider } from "./shared/context/customer/customerSelection.Context";
import { ClientAppProvider } from "./shared/context/clientApplication/clientApplicationSelection.context";
import { ToastMessageProvider } from "./shared/context/toast/toastMessage.Context";
import { ToastNotifications } from "./shared/components/toast-message/toastNotifications.component";
import { GlobalProgressBarProvider } from "./shared/context/globalProgressBar/globalProgressBar.Context";
import { ErrorPage } from "./shared/components/error/errorPage.component";
import { logError } from "./service/error/errorService";
import { ErrorLog } from "./shared/models/shared/error-log/errorLog.model";
import InactivityLogoutTimer from "./shared/components/inactivity/inactivityLogoutTimer.component";
import { SignalRProvider } from "./shared/context/signalR/signalR.Context";
import { loadConfig } from "./config/configLoader"; // Import the config loader
import { SynchronizationData } from "./shared/components/synchronization/synchronization.component";
import { LoadingComponent } from "./shared/components/loading/loading.component";
import { ContextWrapper } from "./shared/components/wrappers/contextWrapper.component";

const Root = ({ hasError }: { hasError: boolean }) => {
  if (hasError) {
    return <ErrorPage />;
  }

  return (
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <MuiXLicense />
        <ThemeProvider theme={theme}>
          <ToastMessageProvider>
            <GlobalProgressBarProvider>
              <ContextWrapper>
                <ClientAppProvider>
                  <SignalRProvider>
                    <CustomerProvider>

                      <RouterProvider router={router} />
                      <SynchronizationData />
                      <ToastNotifications />
                      <InactivityLogoutTimer />

                    </CustomerProvider>
                  </SignalRProvider>
                </ClientAppProvider>
              </ContextWrapper>
            </GlobalProgressBarProvider>
          </ToastMessageProvider>
        </ThemeProvider>
      </MsalProvider>
    </React.StrictMode>
  );
};

const AppInitializer = () => {
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  React.useEffect(() => {
    const loadAppConfig = async () => {
      try {
        await loadConfig();
        await initializeMsalInstance();
        setIsLoading(false);
      } catch (e) {
        console.error("Failed to load configuration or initialize MSAL:", e);
        setHasError(true);
        setIsLoading(false);
      }
    };

    loadAppConfig();

    // Global error handling
    window.addEventListener("error", (e) => {
      const errorData: ErrorLog = {
        message: e.message,
        source: e.filename,
        lineno: e.lineno,
        colno: e.colno,
        error: e.error,
      };

      logError(errorData);
      setHasError(true);
    });

    window.addEventListener("unhandledrejection", function (event) {
      const errorData: ErrorLog = {
        message:
          typeof event.reason === "string"
            ? event.reason
            : event.reason.message,
        source:
          typeof event.reason === "object" && event.reason.stack
            ? event.reason.stack
            : undefined,
        lineno: undefined,
        colno: undefined,
        error: typeof event.reason === "object" ? event.reason : undefined,
      };

      logError(errorData);
      setHasError(true);
    });
  }, []);

  if (isLoading) {
    return <LoadingComponent height="100%" marginTop="0" />;
  }

  return <Root hasError={hasError} />;
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<AppInitializer />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.debug);
