import {
  Box,
  InputAdornment,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { theme } from "../../../../../styles/theme";
import { SetStateAction, useEffect, useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UnfoldMoreOutlinedIcon from "@mui/icons-material/UnfoldMoreOutlined";
import { useSelectedClientApp } from "../../../../context/clientApplication/clientApplicationSelection.context";
import { useSelectedCustomer } from "../../../../context/customer/customerSelection.Context";
import { useTranslation } from "react-i18next";
import { Vessel } from "../../../../models/shared/vessel/vessel.model";
import {
  BrowserType,
  detectBrowser,
} from "../../../../../utils/browser/DetectBrowser";

export function SortedDescendingIcon() {
  return <ExpandMoreIcon className="icon" />;
}

export function SortedAscendingIcon() {
  return <ExpandLessIcon className="icon" />;
}

export function UnsortedIcon() {
  return <UnfoldMoreOutlinedIcon />;
}

interface VesselDataGridModel {
  id: number;
  name: string;
  imoNumber: string;
  lastChangedTime?: string | null;
}

const StyledDataGrid = styled(DataGridPro)(() => ({
  "& .MuiDataGrid-row:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "& .MuiDataGrid-cell": {
    border: "transparent",
  },
  "& .MuiDataGrid-container--top [role=row]": {
    backgroundColor: theme.background.component,
    fontSize: "14px",
  },
  "& .MuiDataGrid-withBorderColor": {
    border: "none",
  },
  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-container--top::after": {
    backgroundColor: "transparent",
  },
  "& .MuiDataGrid-overlayWrapper": {
    height: "30px",
  },
}));

interface DataGridVesselComponentProps {
  item: Vessel | null; // The new item coming from the parent component
}

export const DataGridVessel: React.FC<DataGridVesselComponentProps> = ({
  item,
}) => {
  const [data, setData] = useState([] as VesselDataGridModel[]);
  const [filter, setFilter] = useState("");

  const { selectedClientApp } = useSelectedClientApp();
  const { selectedCustomer } = useSelectedCustomer();
  const [browser, setBrowser] = useState<string>("");

  const { t } = useTranslation();

  const handleFilterChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setFilter(event.target.value);
  };

  const filteredData = data.filter(
    (row) =>
      (row.name?.toLowerCase() ?? "").includes(filter?.toLowerCase()) ||
      (row.imoNumber?.toLowerCase() ?? "").includes(filter?.toLowerCase()) ||
      (row.lastChangedTime?.toString().toLowerCase() ?? "").includes(
        filter?.toLowerCase()
      )
  );

  useEffect(() => {
    if (item) {
      setData((data) => {
        const existingIndex = data.findIndex((row) => row.imoNumber === item.imo);
        const newData = {
          id: existingIndex !== -1 ? data[existingIndex].id : data.length,
          name: item.name,
          imoNumber: item.imo,
          lastChangedTime: (item.updatedDateTime ? new Date(item.updatedDateTime).toLocaleString() : null),
        };
  
        if (existingIndex !== -1) {
          return data.map((row, index) => (index === existingIndex ? newData : row));
        } else {
          return [...data, newData];
        }
      });
    }
  }, [item]);

  useEffect(() => {
    setBrowser(detectBrowser());
    setData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientApp]);

  useEffect(() => {
    if (selectedClientApp && selectedCustomer) {
      if (selectedCustomer.vessels == null || selectedCustomer.vessels?.length === 0) {
        setData([]);
        return;
      }
      if (selectedCustomer.vessels){
        setData(
          selectedCustomer.vessels.map((vessel, index) => {
            return {
              id: index,
              name: vessel.name,
              imoNumber: vessel.imo,
              lastChangedTime: (vessel.updatedDateTime ? new Date(vessel.updatedDateTime).toLocaleString() : null)
            };
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        backgroundColor: theme.background.component,
        borderRadius: "8px",
        marginTop: "10px",
      }}
    >
      <Box>
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            verticalAlign: "middle",
            justifyContent: "space-evenly",
            padding: "5px",
            height: "60px",
          }}
        >
          <Box
            sx={{
              flex: 1,
            }}
          >
            <Typography
              sx={{
                background: theme.background.componentLight,
                borderRadius: "8px",
                fontSize: "20px",
                width: "fit-content",
                height: "30px",
                textAlign: "left",
                paddingLeft: "5px",
                paddingRight: "5px"
              }}
            >
              {t("component.vessel.dataGrid.info.count")}: {filteredData.length}
            </Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
              justifyContent: "flex-end",
            }}
          >
            <TextField
              id="outlined-basic"
              label="Search"
              variant="outlined"
              sx={{ width: "300px", float: "right" }}
              onChange={handleFilterChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Stack>
      </Box>
      <Box sx={{
        display: "flex",
        flexDirection: "column",
        height: "32vh",
        margin: 0,
        [theme.breakpoints.down("xl")]: {
          height: browser === BrowserType.CHROME ? "32vh" : "34vh",
        },
        [theme.breakpoints.down("lg")]: {
          height: browser === BrowserType.CHROME ? "22vh" : "24vh",
        },
        [theme.breakpoints.down("mdPlus")]: {
          height: browser === BrowserType.CHROME ? "12vh" : "14vh",
        },
      }}>
        <StyledDataGrid
          sx={{
            border: "none",
            BorderStyle: "none !important",
            rowBorderColor: "red",
          }}
          rows={filteredData}
          columns={[
            {
              field: "name",
              headerName: t("component.vessel.dataGrid.column.name"),
              flex: 1,
            },
            {
              field: "imoNumber",
              headerName: t("component.vessel.dataGrid.column.imo"),
              flex: 1,
            },
            {
              field: "lastChangedTime",
              headerName: t("component.vessel.dataGrid.column.lastChangedTime"),
              flex: 1,
            },
          ]}
          disableRowSelectionOnClick
          hideFooter
          disableColumnMenu
          disableDensitySelector
          slots={{
            columnSortedDescendingIcon: SortedDescendingIcon,
            columnSortedAscendingIcon: SortedAscendingIcon,
            columnUnsortedIcon: UnsortedIcon,
          }}
          localeText={{
            noRowsLabel: t("component.vessel.dataGrid.info.no_data"),
          }}
        />
      </Box>
    </Box>
  );
};

export default DataGridVessel;
