import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    MenuItem,
    Select,
    styled,
    TextField,
    Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import React, {ReactNode, useEffect, useState} from "react";
import {theme} from "../../../../../styles/theme";
import {CustomPortService} from "../../../../../service/api/port/portService";
import {Country} from "../../../../models/port/protoPort.model";
import {SearchUNPortsResult} from "../../../../models/port/port.model";
import SearchIcon from "@mui/icons-material/Search";
import {dataGridPageSize, DataRequest} from "./searchUnecePorts.component";

const textColor = theme.palette.text.primary;
const backgroundColor = "#141D27";
const borderColor = "#E8ECED";
const borderWidth = "2px";
const disabledTextColor = theme.palette.text.primary;
const locodePageSize = 100;

const TextFieldCustom = styled(TextField)({
    "& .MuiInputLabel-root": {
        color: textColor,
        transform: "translate(14px, -20px) scale(1)",
    },
    "& .MuiInputBase-root.Mui-disabled": {
        backgroundColor: "transparent",
        "& fieldset": {
            borderColor: borderColor,
            borderWidth: borderWidth,
        },
    },
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            borderColor: borderColor,
        },
        color: textColor,
        backgroundColor: theme.background.componentLight
    },
    "& .MuiInputBase-input": {
        color: textColor,
        padding: "6px"
    },
    "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: disabledTextColor,
    },
    "& label.Mui-focused": {
        color: textColor
    }
});

const SelectCustom = styled(Select)({
    "& .MuiInputLabel-root": {
        color: textColor,
        transform: "translate(14px, -20px) scale(1)",
    },
    "& .MuiInputBase-root.Mui-disabled": {
        backgroundColor: "transparent",
        "& fieldset": {
          borderColor: borderColor,
          borderWidth: borderWidth,
        },
    },
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: borderColor,
        },
        color: textColor,
        backgroundColor: backgroundColor,
    },
    "& .MuiInputBase-input": {
        color: textColor,
    },
    "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: disabledTextColor,
    },
    "& label.Mui-focused": {
        color: textColor
    },
    "&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline": {
        borderColor: borderColor
    }
});

const CustomAutocomplete = styled(Autocomplete<string, false, false, true>)({
    "& .MuiInputBase-root": {
        marginTop: "0px"
    },
    "& .MuiFormControl-root": {
        marginTop: "0px"
    },
    "& .MuiOutlinedInput-root": {
        paddingTop: "1px",
        paddingBottom: "1px",
        paddingLeft: "0px"
    },
    "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
        paddingBottom: "5px",
        paddingTop: "5px",
        paddingLeft: "6px"
    }
})

interface ComponentProperties {
    onSearchDoneHandler: (searchResult: SearchUNPortsResult) => void;
    dataRequest?: DataRequest;
    setIsNewSearch: (isNewSearch: boolean) => void;
    setIsDataLoading: (isDataLoading: boolean) => void;
    reset: boolean;
}

export const Search = (props: ComponentProperties) => {
    const customPortService = new CustomPortService();
    const {t} = useTranslation();
    const [portCountryCode, setPortCountryCode] = useState<string>("EMPTY");
    const [portLocode, setPortLocode] = useState<string>("");
    const [portLocodeSearchPageNumber, setPortLocodeSearchPageNumber] = useState<number>(0);
    const [portName, setPortName] = useState<string>("");
    const [portCountries, setPortCountries] = useState<Country[]>([]);
    const [portLocodes, setPortLocodes] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [locodesLoading, setLocodesLoading] = useState(false);
    const [lastLocode, setLastLocode] = useState("");

    const onCountrySelectedHandler = (event: any) => {
        const countryCode: string = event.target.value;
        props.onSearchDoneHandler({ data: [], dataSource: []} as SearchUNPortsResult);
        setPortLocodes([]);
        setPortCountryCode(countryCode);
        setPortLocode("");
        setPortName("");
        if (countryCode !== "EMPTY") {
            if (!locodesLoading) {
                setLocodesLoading(true);
                setLastLocode("");
                setPortLocode("LOADING");
                customPortService.getPortLocodesAsync(countryCode, locodePageSize, portLocodeSearchPageNumber)
                    .then((response) => {
                        setPortLocodes(response);
                        setPortLocode("");
                        setPortLocodeSearchPageNumber(0);

                        if (response.length < locodePageSize) {
                            setLastLocode(response[response.length - 1]);
                        }
                        search(null, countryCode, "", "");
                    })
                    .finally(() => setLocodesLoading(false));
            }
        } else {
            setPortLocodes([]);
            setPortLocode("");
        }
    };
    const onLocodeInputHandler = (event: any) => {
        const locode = event ? event.target.value as string : "";
        setPortLocode(locode);
    };
    const onLocodeSelectedHandler = (event: any) => {
        const locode = event.target.innerText as string;
        if(locode !== undefined){
            props.onSearchDoneHandler({ data: [], dataSource: []} as SearchUNPortsResult);
            setPortLocode(locode);
            setPortName("");
            search(null, portCountryCode, locode, "");
        }
    };
    const searchLocodeOnScrollHandler = (e: any) => {
        const isBottom = e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
        if (lastLocode.length === 0 && isBottom && !locodesLoading) {
            const nextPageNumber = portLocodeSearchPageNumber + 1;
            setLocodesLoading(true);
            customPortService.getPortLocodesAsync(portCountryCode, locodePageSize, nextPageNumber)
                .then((response) => {
                    setPortLocodes(portLocodes.concat(response));
                    setTimeout(() => { e.target.scrollTop = e.target.scrollHeight }, 1000);
                    if (response.length === locodePageSize) {
                        setPortLocodeSearchPageNumber(nextPageNumber);
                    } else if (response.length > 0) {
                        setLastLocode(response[response.length -1]);
                    } else {
                        setLastLocode(portLocodes[portLocodes.length - 1]);
                    }
                })
                .finally(() => setLocodesLoading(false));
        }
    };

    const renderSelectedValue = (value: any, emptyText: string, options: Country[] | null): ReactNode => {
      if (value === "EMPTY") {
          return <Typography color="gray">{emptyText}</Typography>;
      }
      if (value === "LOADING") {
          return <Typography color="gray" paddingTop="7px" textAlign="center">
              <CircularProgress size={24} />
          </Typography>;
      }

      if (options !== null) {
          return options.find((option) => option.code === value)?.name
      }

      return value;
    };
    const search = (
        event: any | null,
        countryCode: string | null,
        locode: string | null,
        name: string | null) => {
        let dataRequest: DataRequest | undefined = inputDataRequest;
        if (event || countryCode || locode || name) {
            dataRequest = {
                sort: "",
                sortOrder: "",
                pageSize: dataGridPageSize,
                pageNumber: 0
            } as DataRequest;
            props.setIsNewSearch(true);
        } else {
            props.setIsNewSearch(false);
        }

        let searchCountryCode = countryCode ?? portCountryCode;
        let searchLocode = locode ?? portLocode;
        let searchPortName = name ?? portName;

        if (searchCountryCode === "EMPTY") {
          searchCountryCode = "";
        }

        if (searchCountryCode.length > 0 || searchLocode.length > 0 || searchPortName.length > 0) {
          setLoading(true);
          props.setIsDataLoading(true);
          customPortService.searchUnecePortsAsync(
              searchCountryCode,
              searchLocode,
              searchPortName,
              dataRequest?.sort ?? "",
              dataRequest?.sortOrder ?? "",
              dataRequest?.pageSize ?? 50,
              dataRequest?.pageNumber ?? 0)
              .then((response) => {
                      props.onSearchDoneHandler(response);
                  }
              )
              .finally(() => {
                setLoading(false);
                props.setIsDataLoading(false);
              });
        }
    };
    let inputDataRequest = props.dataRequest;

    useEffect(() => {
        if (props.reset) {
            setPortCountryCode("EMPTY");
            setPortLocode("");
            setPortLocodes([]);
            setPortName("");
            setLastLocode("");
            setPortLocodeSearchPageNumber(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.reset]);

    useEffect(() => {
        if (props.dataRequest) {
            search(null, null, null, null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputDataRequest]);

    useEffect(() => {
        customPortService.getPortCountriesAsync()
            .then((response) => { setPortCountries(response); });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Box sx={{
        display: "flex",
        direction: "row",
        paddingLeft: "5px",
        paddingRight: "5px",
      }}>
        <SelectCustom
            renderValue={(value: any) => { return renderSelectedValue(value, "COUNTRY", portCountries) }}
            autoWidth={false}
            sx={{
              height: "35px",
              width: "28%",
              backgroundColor: theme.background.componentLight,
              color: "#6c7177"
            }}
            value={portCountryCode}
            onChange={onCountrySelectedHandler}
            MenuProps={{
                PaperProps: {
                    style: { maxHeight: 200 }
                }
            }}
        >
          <MenuItem value="EMPTY" key="select-country-key" sx={{color: "darkgray"}}>COUNTRY</MenuItem>
          {portCountries.map((country) => (
              <MenuItem value={country.code} key={country.code}>
                {country.name}
              </MenuItem>
          ))}
        </SelectCustom>
        <CustomAutocomplete
            freeSolo
            sx={{
              width: "26%",
              marginLeft: "10px"
            }}
            value={portLocode}
            options={portLocodes}
            ListboxComponent={(props) => (
                <div
                    style={{
                        maxHeight: "200px",
                        backgroundColor: "#495057"
                    }}
                    {...props}
                    onScroll={searchLocodeOnScrollHandler}
                />
            )}
            onInputChange={onLocodeInputHandler}
            onChange={onLocodeSelectedHandler}
            renderOption={(props, option) => (
                <li
                    style={{ backgroundColor: option === portLocode ? "#5c626a": "#495057" }}
                    key={option} {...props}>{option}</li>
            )}
            renderInput={(params) => <TextFieldCustom
              sx={{
                  height: "35px"
              }}
              {...params}
              placeholder="UN/LOCODE"
            />}
        />
        <TextFieldCustom
          disabled={false}
          value={portName}
          onChange={(e: any) => { setPortName(e.target.value as string); }}
          placeholder="NAME"
          sx={{
              width: "26%",
              marginLeft: "10px"
          }}
        />
          <Box sx={{
              display: "flex",
              width: "20%",
              paddingLeft: "15px",
          }}>
              <Button
                  sx={{
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.secondary.contrastText,
                      paddingTop: "0",
                      paddingBottom: "0",
                      width: "100px"
                  }}
                  id="searchUnecePortsButton"
                  onClick={(e: any) => {search(e, null, null, null);}}
                  endIcon={<SearchIcon />}
                  disabled={loading}
              >
                  {loading ? (
                      <span style={{
                          width: "60px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center"
                      }}><CircularProgress sx={{color: "blue"}} size={24}/></span>
                  ) : (
                      <span style={{ width: "50px" }}>{t("component.port.action.search")}</span>
                  )}
              </Button>
          </Box>
      </Box>
  );
};

export default Search;