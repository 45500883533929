import { Box, styled, Tab, Tabs } from "@mui/material";
import { theme } from "../../styles/theme";
import React, { useEffect, useMemo } from "react";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";

const vesselsRoutePath = "/vessel";
const vesselsParentRoutePath = `${vesselsRoutePath}/`;
const vesselGroupingCatalogRoutePath = `${vesselsRoutePath}/grouping-catalog`;
const vesselManagementRoutePath = `${vesselsRoutePath}/management`;

const StyledTabs = styled(Tabs)(() => ({
  "& .MuiTabs-scroller": {
    display: "flex",
  },
}));

export function VesselRoute() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  
  const tabs = useMemo(
    () => [
      {
        label: t("routes.vessels.vessel_grouping_catalog"),
        value: vesselGroupingCatalogRoutePath,
        id: "VesselGroupingCatalogTab",
      },
      {
        label: t("routes.vessels.vessels_management"),
        value: vesselManagementRoutePath,
        id: "VesselManagementTab",
      },
    ],
    [t]
  );
 
  const currentTab =
    tabs.find((tab) => location.pathname.startsWith(tab.value))?.value ||
    vesselGroupingCatalogRoutePath;

  useEffect(() => {
    if (!location.pathname.startsWith(vesselsParentRoutePath)) {
      navigate(vesselGroupingCatalogRoutePath, { replace: true });
    }
  }, [location.pathname, navigate]);

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    navigate(newValue);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Box>
        <StyledTabs
          value={currentTab}
          onChange={handleChange}
          sx={{
            display: "inline-block",
            color: theme.palette.primary.light,
            backgroundColor: theme.background.componentLight,
            marginLeft: "-24px",
          }}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.id}
              label={tab.label}
              value={tab.value}
              id={tab.id}
              sx={{ width: "auto" }}
            />
          ))}
        </StyledTabs>
      </Box>
      <Outlet />
    </Box>
  );
}