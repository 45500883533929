import React, {useEffect, useState} from "react";
import { Box } from "@mui/material";
import VesselGroupingCatalogHeader from "../../shared/components/vessel/grouping-catalog/grid/vesselGroupingHeader.component";
import DataGridVesselGrouping from "../../shared/components/vessel/grouping-catalog/grid/dataGridVesselGrouping.component";
import VesselGroupingDetailsModal from "../../shared/components/vessel/grouping-catalog/item/vesselGroupingDetailsModal.component";
import { VesselGroupingModel } from "../../shared/models/vessel/modelVesselGrouping.model";
import { useSelectedCustomer } from "../../shared/context/customer/customerSelection.Context";

export function VesselGroupingCatalogRoute() {
    const [initDataReload, setInitDataReload] = useState(false);
    const [openItemDetails, setOpenItemDetails] = useState(false);
    const [, setEditItem] = useState<VesselGroupingModel | null>(null);
    const [vesselGroupingCategories, setVesselGroupingCategories] = useState<string[]>([]);
    const itemCreatedHandler = (isItemCreated: boolean) => {
        if (isItemCreated) {
            setInitDataReload(true);
        }
    };
    const groupingDataReloadedHandler = (isDataReloaded: boolean) => {
        if (isDataReloaded) {
            setInitDataReload(false);
        }
    };
    const groupingDetailsModalCloseHandler = () => {
        setOpenItemDetails(false);
        setEditItem(null);
    };
    const addGroupingButtonClickedHandler = () => {
        setOpenItemDetails(true);
        setEditItem(null);
    };
    const editClickHandler= (vesselGroupingModel: VesselGroupingModel) => {
        setOpenItemDetails(true);
        setEditItem(vesselGroupingModel);
    };

    // CustomerUtility.GetSelectedCustomerId has a delay when the customer changed.
    let selectedCustomerId = useSelectedCustomer().selectedCustomer?.id ?? "";

    useEffect(() => {
        setOpenItemDetails(false);
    }, [selectedCustomerId]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%%",
                 marginTop: "10px"
            }}
        >
            <VesselGroupingCatalogHeader
                key="vessel_grouping_catalog"
                onAddCatalogButtonClickedHandler={addGroupingButtonClickedHandler} />
            <DataGridVesselGrouping
                initDataReload={initDataReload}
                onDataReloaded={groupingDataReloadedHandler}
                onEditClickHandler={editClickHandler}
                setGroupingCategories={setVesselGroupingCategories} />
            <VesselGroupingDetailsModal
                open={openItemDetails}
                onClose={groupingDetailsModalCloseHandler}
                onGroupingCreatedHandler={itemCreatedHandler}
                vesselGroupingCategories={vesselGroupingCategories}
                editGrouping={null} />
        </Box>
    );
}