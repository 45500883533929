export const storageConst = {
  SELECTED_CLIENT_APP: "selectedClientApp",
  SELECTED_CUSTOMER: "selectedCustomer",
  SESSION_ID: "sessionId",
  USER_ID: "userId",
  VESSELS_IN_PROCESS: "vesselsInProcess"
};

export const signalRConstants = {
  CCACTIONTYPE: "cc-action-type"
};

export const clientConstants = {
  MDF_KEYWORD: "mdf",
  ONBOARDING_KEYWORD: "ui_tool"
};