import { MenuItem, Select } from "@mui/material";
import { theme } from "../../../styles/theme";
import { useSelectedClientApp } from "../../context/clientApplication/clientApplicationSelection.context";
import { ISelectedClientApplication } from "../../context/clientApplication/selectedClientApplication.context.model";
import { useEffect, useState } from "react";
import { Client } from "../../models/client/client.model";
import { ClientService } from "../../../service/api/client/clientService";
import { useToastMessageService } from "../../context/toast/toastMessage.Context";
import { useGlobalProgressBarService } from "../../context/globalProgressBar/globalProgressBar.Context";
import { useNavigate } from "react-router-dom";
import { ClientApplicationType } from "../../type/clientApplication.type";
import { clientConstants } from "../../../constants/global.constants";

export const ClientApplicationSelect = () => {
  const [clientAppItems, setClientAppItems] = useState<ClientApplicationType[]>(
    []
  );

  const { addProgressItem, removeProgressItem } = useGlobalProgressBarService();

  const { addToast } = useToastMessageService();
  const { selectClientApp, selectedClientApp, loading } =
    useSelectedClientApp();
  const [selectValue, setSelectValue] = useState<string>("");
  const clientService = new ClientService();
  const navigate = useNavigate();

  const handleClientAppSelect = (appId: string) => {
    const data: ISelectedClientApplication = {
      clientApp:
        clientAppItems.find((app) => app.id === appId) ?? clientAppItems[0],
    };

    if (data != null && data.clientApp != null) {
      selectClientApp(data);
    } else {
      addToast("error", "Client not found");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        addProgressItem();

        const clients: Client[] = (await clientService.getClientsService())
          .data as Client[];

        if (clients?.length > 0) {
          const newClientAppItems = clients
          .filter((client) => client.Tags !== clientConstants.ONBOARDING_KEYWORD)
          .map((client) => ({
            id: client.ClientId,
            name: client.ClientName,
            description: client.ClientName,
            tags: client.Tags,
          })) as ClientApplicationType[];

          setClientAppItems(newClientAppItems);

          if (selectedClientApp?.clientApp?.id == null) {
            const firstClientApp = newClientAppItems[0];
            setSelectValue(firstClientApp.id);
            selectClientApp({ clientApp: firstClientApp });
          }
        }

        return clients;
      } catch (error) {
        addToast("error", "Error fetching client applications");
      } finally {
        removeProgressItem();
      }
    };

    const fetchDataAsync = async () => {
      await fetchData();
    };

    if (!loading) {
      fetchDataAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (
      selectedClientApp?.clientApp?.id &&
      clientAppItems.length > 0 &&
      clientAppItems.find((app) => app.id === selectedClientApp?.clientApp?.id)
    ) {
      navigate("/");
      setSelectValue(selectedClientApp?.clientApp?.id.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientApp, clientAppItems]);

  return (
    <Select
      name="ClientApplicationSelect"
      autoWidth={true}
      value={selectValue}
      sx={{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.contrastText,
        height: "46px",
        width: "95%",
      }}
      onChange={(e) => handleClientAppSelect(e.target.value as string)}
    >
      {clientAppItems.map((app) => (
        <MenuItem value={app.id} key={app.id} data-id="ClientAppItem">
          {app.description}
        </MenuItem>
      ))}
    </Select>
  );
};
