import { BaseService } from "../BaseService";
import { headerKey, path } from "./vesselBaseService.constants";
import { headerKey as generalHeaderKey } from "../common.constants";
import { UserUtility } from "../../../utils/data/user.utility";
import { SessionUtility } from "../../../utils/data/session.utility";
import { ResponseMessage } from "../../../shared/models/shared/error/errorResponse.model";
import { CRUDResponse } from "../../../shared/models/shared/CRUDResponse.model";
import i18n from "i18next";

export class CommunicationAdapterFunctionService extends BaseService {
  public async communicationAdapterFunctionAsync(
    clientId: string,
    tenantId: string,
	vesselImos: string
  ): Promise<CRUDResponse<ResponseMessage>> {

	let crudResponse: CRUDResponse<ResponseMessage> = {
		success: [],
		errors: [],
	};

	try {
		let response = await this.post<ResponseMessage>(
		  path.COMMUNICATION_ADAPTER,
		  vesselImos,
		  {
			headers: {
				[headerKey.CC_ACTIVE_CLIENT]: clientId,
				[headerKey.CC_TENANT_ID]: tenantId,
				[generalHeaderKey.CC_CORRELATION_ID]: SessionUtility.GetSessionId(),
				[generalHeaderKey.CC_USER_ID]: UserUtility.GetUserId(),
				"Content-Type": "application/json",
			},
		  }
		);
  
		if (this.isResponseSuccess(response)) {
		  crudResponse.success.push(
			i18n.t("service.api.request_has_been_submited_successfully")
		  );
		}
	  } catch (error: any) {

		if (error.response) {
		  let message: any = error?.response?.data;
  
		  if (message?.errors && Array.isArray(message.errors)) {
			crudResponse.errors = message.errors.map((obj: any) => obj.message);
		  } else {
			crudResponse.errors.push("An unknown error occurred.");
		  }
		} else {
		  crudResponse.errors.push(
			error?.message?.toString() || "An unknown error occurred."
		  );
		}
	  }

	  return crudResponse;
  }
}